<template>
  <div class="qr-page">
    <b-spinner
      v-if="isLoading"
      variant="dark"
      type="grow"
    />

    <b-card
      v-else
      style="width: 100%; max-width: 380px; margin-bottom: 0;"
      :border-variant="getVariantButton"
      header="QR CHECK"
      :header-bg-variant="getVariantButton"
      header-text-variant="white"
      align="center"
    >
      <b-card-title class="pt-1">
        {{ headerTitle }}
      </b-card-title>

      <b-card-text>
        {{ userCommunity.email }}
      </b-card-text>
      <b-card-text>
        {{ communityId }}
      </b-card-text>
      <b-card-body>
        <b-row>
          <b-col
            class="text-center"
            cols="12"
          >
            <b-img-lazy
              v-if="userCommunity.avatarURL"
              :src="userCommunity.avatarURL"
              alt="avatar"
              style="border:1px; border-radius: 50%; width: 100%; max-width: 220px;"
            />
          </b-col>

          <b-col
            cols="12"
            class="mt-2"
          >
            <b-button block :variant="getVariantButton" size="lg">
              {{ getStatusLabel }}
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { getCommunityMembers } from '@/services/backend/communities/communityMembersService';

export default {
  name: 'QRPage',

  props: {
    communityId: {
      type: String,
      required: true,
    },
    username: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      userCommunity: {},
    };
  },

  computed: {
    headerTitle() {
      return `${this.userCommunity.name} ${this.userCommunity.surname}`;
    },

    getStatusLabel() {
      switch (this.userCommunity.status) {
        case 0:
          return this.$t('backoffice.members.pending');
        case 1:
          return this.$t('backoffice.members.requested-join');
        case 2:
          return this.$t('backoffice.members.rejected');
        case 3:
          return this.$t('backoffice.members.confirmed');
        case 4:
          return this.$t('backoffice.members.rejected-admin');
        case 5:
          return this.$t('backoffice.members.rejected-user');
        case 6:
          return this.$t('backoffice.members.left');
        case 7:
          return this.$t('backoffice.members.invited');
        case 8:
          return this.$t('backoffice.members.waiting-list');
        case 81:
          return this.$t('backoffice.members.waiting-form');
        default:
          return '????';
      }
    },

    getVariantButton() {
      switch (this.userCommunity.status) {
        case 0:
          return 'warning';
        case 2:
        case 4:
          return 'danger';
        case 3:
          return 'success';
        default:
          return 'info';
      }
    },
  },

  async created() {
    this.isLoading = true;
    const params = {
      communitySlug: this.communityId,
      username: this.username,
      isForProfile: true,
      isForBackoffice: 1,
    };
    const { items } = await getCommunityMembers(params);
    // console.log(items);
    if (items.length) {
      const [user] = items;
      const {
        avatarURL, headline, name, surname, relationsCount, status, email,
      } = user;
      this.userCommunity = {
        avatarURL, headline, name, surname, relationsCount, status, email,
      };
    }
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
.qr-page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-header:first-child {
  justify-content: center;
  font-weight: 700;
}
</style>
